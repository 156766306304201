.InfoBox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1vmin;
  margin: 1vmax;
  align-items: center;
}

.InfoBoxImage {
  width: 100%;
  margin: 1vmax;
  border-radius: 1vmin;
}

.InfoBoxVideo {
  width: 100%;
  margin: 0vmax;
  border-radius: 1vmin;
}
