.Home {
  text-align: center;
}

.HomeHeader {
  background-color: #282c34;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ProfileHeader {
  margin: 30vh;
}

#ProfileImage {
  object-fit: cover;
  width: 450px;
  height: 450px;
  border-radius: 15%;
}

.Linebreak {
  background-color: black;
  margin: 5vh;
  height: 1vh;
  width: 85vw;
  border-radius: 0.5vh;
}

.NodeContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vmin;
}

.ContactContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10vmin;
  margin: 5vh;
}

.ContactButton {
  width: 15vmin;
  height: 15vmin;
  border-radius: 1vmin;
  background-color: rgb(74, 74, 74);
}

.ContactLogo {
  max-width: 95%;
  max-height: 95%;
  width: auto;
  height: auto;
}
