.NavigationBar {
  background-color: #181a1f;
  position: sticky;
  top: 0;
  z-index: 9000;
  height: 4vh;
  column-gap: 1vw;
  padding: 1vw;
  border-bottom: solid;
}

.NavItem {
  color: white;
  width: 10vh;
  height: 5vh;
  padding: 1vh;
  border-color: black;
  background-color: gray;
  border-style: solid;
  border-radius: 1vh;
  text-align: center;
}

a {
  color: white;
}

.NavContactContainer {
  float: right;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3vh;
}

.NavContactButton {
  width: 5vh;
  height: 5vh;
  border-radius: 1vh;
  background-color: rgb(74, 74, 74);
}

.NavContactLogo {
  max-width: 95%;
  max-height: 95%;
  width: auto;
  height: auto;
}
