.ProjectHeader {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: calc(10px + 2vmin);
    color: white;
    padding-right: 5vmin;
    padding-left: 5vmin;
}

.ProjectLink {
    color:white;
}

.ProjectDescription {
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 5vh;
}