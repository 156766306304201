.Node {
    position: relative;
    --width: 20em;
    --height: 17em;
    --background-color: #26bfe9;
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    font-size: large;
    border-style: solid;
    border-width: 0.15vmax;
    color: black;
    border-color: black;
    margin: 0.5vmax;
    padding: 0.25em;
  }
  
  /*    Node divs */
  
  .NodeHeader {
    display: flex;
    flex-direction: row;
  }
  
  .NodeTitleDate {
    margin: 0%;
  }
  
  /*    Node elements */
  
  .NodeImage {
    max-width: calc(1em * 5);
    max-height: calc(1em * 5);
    border-radius: 1em;
    border-style: solid;
    border-width: inherit;
    margin: 0.35em;
    color: inherit;
  }
  
  .NodeTitle {
    align-content: center;
    font-size: 1.5em;
    margin-left: 0.5em;
    margin-top: 0.25em;
    margin-bottom: 0;
  }
  
  .NodeDate {
    margin-top: 0;
    margin-left: 0.25em;
    margin-bottom: 0;
  }
  
  .NodeDescription {
    text-align: left;
    margin: 0.25ch;
    font-size: medium;
  }
  
  .NodeLink {
    position: absolute;
    color: inherit;
    font-weight: bold;
    bottom: 5px;
    right: 10px;
  }
  
  /*    Node animations */

  /* This is the starting state (circle no animation) */
  .Node.start {
    animation: Box-to-Circle 0s ease-in forwards, To-Transparent 0s ease-in forwards;
    user-select: none;
  }
  
  .Node.off {
    animation: Box-to-Circle 0.5s ease-in forwards, To-Transparent 0.5s ease-in forwards;
    user-select: none;
  }
  
  .Node.on {
    animation: Circle-to-Box 0.5s ease forwards, To-Color 0.5s ease forwards;
    user-select: initial;
  }
  
  .NodeImage.off {
    animation: To-Clear 0.5s ease-in forwards;
  }
  
  .NodeImage.on {
    animation: To-Opaque 0.5s ease forwards;
  }
  
  @keyframes Box-to-Circle {
    0% {
      height: var(--height);
      width: var(--width);
      border-radius: 1em;
      margin: 0.5vmax;
    }
    100% {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      font-size: 0%;
      margin-right: calc(var(--width) * 0.5 - 1rem);
      margin-left: calc(var(--width) * 0.5 - 1rem);
      margin-top: calc(var(--height) * 0.5 - 1rem);
      margin-bottom: calc(var(--height) * 0.5 - 1rem);
    }
  }
  
  @keyframes Circle-to-Box {
    0% {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
      font-size: 0%;
      margin-right: calc(var(--width) * 0.5 - 1rem);
      margin-left: calc(var(--width) * 0.5 - 1rem);
      margin-top: calc(var(--height) * 0.5 - 1rem);
      margin-bottom: calc(var(--height) * 0.5 - 1rem);
    }
    100% {
      height: var(--height);
      width: var(--width);
      border-radius: 1em;
      margin: 0.5vmax;
    }
  }
  
  @keyframes To-Transparent {
    0% {
      color: black;
    }
    10%, 100% {
      color: transparent;
    }
  }
  
  @keyframes To-Color {
    0%, 90% {
      color: transparent;
    }
    100% {
      color: black;
    }
  }
  
  @keyframes To-Clear {
    0% {
      opacity: 1;
    }
    10%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes To-Opaque {
    0%, 90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }